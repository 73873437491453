import { type FC, type PropsWithChildren, useRef } from 'react';
import { cva } from 'class-variance-authority';
import {
  Button as AriaButton,
  type ButtonProps as AriaButtonProps,
} from 'react-aria-components';

export type ButtonVariant =
  | 'primary'
  | 'secondary'
  | 'disabled'
  | 'tetriary'
  | 'destroy'
  | 'clean'
  | 'tight';

export type ButtonProps = {
  className?: string;
  variant?: ButtonVariant;
} & PropsWithChildren &
  AriaButtonProps;

export const Button: FC<ButtonProps> = ({
  className,
  children,
  variant = 'secondary',
  ...props
}) => {
  const ref = useRef<HTMLButtonElement>(null);
  return (
    <AriaButton
      ref={ref}
      className={buttonVariants({ className, variant })}
      {...props}
    >
      {children}
    </AriaButton>
  );
};

export const buttonVariants = cva(
  'rounded-full inline flex justify-center items-center gap-2 px-5 py-2 active:translate-y-0.5 outline-none',
  {
    variants: {
      variant: {
        primary: 'bg-primary text-white hover:bg-primary/80 ',
        secondary: 'bg-white border border-black hover:bg-primary/30',
        disabled: 'bg-disabled text-white',
        tetriary: 'text-black',
        destroy: 'bg-error text-white',
        tight: '!px-2 !py-1 ',
        clean: '',
      },
    },
    defaultVariants: {
      variant: 'secondary',
    },
  },
);
